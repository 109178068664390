import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.webp";

const Footer = () => {
  const gotoTop = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  return (
    <footer>
      <footer className="footer-section mt-3">
        <div className="container">
          <div className="footer-cta pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fas fa-map-marker-alt" />
                  <div className="cta-text">
                    <h2 style={{color:"white"}}>Find us</h2>
                    <span>6901 Howdershell Rd Hazelwood, MO, 63042</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fas fa-phone" />
                  <div className="cta-text">
                    <h2 style={{color:"white"}}>Call us</h2>
                    <span>314-274-2828</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="far fa-envelope-open" />
                  <div className="cta-text">
                    <h2 style={{color:"white"}}>Mail us</h2>
                    <span>contact@makfuel.com</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    {/* <img
                        src="https://i.ibb.co/QDy827D/ak-logo.png"
                        className="img-fluid"
                        alt="logo"
                      /> */}
                    <Link to="/" title="MAKFUELS">
                      <img
                        // height={50}
                        // width={50}
                        onClick={gotoTop}
                        className="header-img footer_also"
                        src={logo}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="footer-text">
                    {/* <p>
                      Lorem ipsum dolor sit amet, consec tetur adipisicing elit,
                      sed do eiusmod tempor incididuntut consec tetur
                      adipisicing elit,Lorem ipsum dolor sit amet.
                    </p> */}
                  </div>
                  <div className="footer-social-icon">
                    <span>Follow us</span>
                    <a
                      href="https://www.facebook.com/makfuelsofficial"
                      rel="noreferrer"
                      target="_blank"
                      title="https://www.facebook.com/makfuelsofficial"
                    >
                      <i className="fab fa-facebook-f facebook-bg" />
                    </a>
                    {/* <a href="#">
                      <i className="fab fa-twitter twitter-bg" />
                    </a>
                    <a href="#">
                      <i className="fab fa-google-plus-g google-bg" />
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h2  style={{color:"white"}}>Useful Links</h2>
                  </div>
                  <ul style={{ padding: "0px" }}>
                    <li>
                      <Link to="/" className="footer_a_tag" onClick={gotoTop}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about-us"
                        className="footer_a_tag"
                        onClick={gotoTop}
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact-us"
                        className="footer_a_tag"
                        onClick={gotoTop}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/privacy-policy"
                        className="footer_a_tag"
                        onClick={gotoTop}
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/terms-of-use"
                        className="footer_a_tag"
                        onClick={gotoTop}
                      >
                        Terms Condition
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h2  style={{color:"white"}}>Subscribe</h2>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                      Don’t miss to subscribe to our new feeds, kindly fill the
                      form below.
                    </p>
                  </div>
                  <div className="subscribe-form">
                    <form action="#" >
                      <input type="text" placeholder="Email Address" />
                      <button  title="send">
                        <i className="fab fa-telegram-plane" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="">
            <div className="row">
              <div className="col-xl-4 col-lg-4 text-center text-lg-left">
                <div className="copyright-text">
                  <p>Copyright © 2024, All Right Reserved </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 d-none d-lg-block text-right ">
                <div className="footer-menu">
                  <ul style={{textAlign:"right"}}>
                    <li>
                      <Link to="/" className="footer_a_tag" onClick={gotoTop}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about-us"
                        className="footer_a_tag"
                        onClick={gotoTop}
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact-us"
                        className="footer_a_tag"
                        onClick={gotoTop}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/privacy-policy"
                        className="footer_a_tag"
                        onClick={gotoTop}
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/terms-of-use"
                        className="footer_a_tag"
                        onClick={gotoTop}
                      >
                        Terms Condition
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </footer>
    //   <footer>
    //   <div className="footer___">© {new Date().getFullYear()} <span>MAK FUELS</span>. All Rights Reserved</div>
    // </footer>
  );
};

export default Footer;
